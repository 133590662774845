<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="10">
        <eb-date-range
          type="year"
          v-model="dateRange"
          @change="changeDate"
        ></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <!-- <el-button type="primary" @click="doExportUserOrderList">导出报表</el-button> -->
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="月份" prop="date"></el-table-column>
      
      <el-table-column label="月卡" prop="monthSum"></el-table-column>
      <el-table-column label="半月卡" prop="halfSum"></el-table-column>

      <el-table-column label="总购买次数" prop="paynum">
        <template #default="scope">
          <span>{{ scope.row.monthSum + scope.row.halfSum + scope.row.otherSum}}</span>
        </template>
      </el-table-column>

      <el-table-column label="总金额" prop="amount">
        <template #default="scope">
          <span>{{ util.formatNum(scope.row.amount / 100) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平均套餐金额" prop="averagePay">
        <template #default="scope">
          <span v-if="scope.row.amount">{{ util.formatNum(scope.row.amount / (scope.row.halfSum + scope.row.monthSum) / 100) }}</span>
          <span v-else>0</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { fetchOrderStatistics} from "@/api/order";
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    let startTime = moment().startOf("year").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");

    let userId = this.$router.currentRoute._value.query.id;
    return {
      count: {
        money: 0,
        len: 0,
        avg: 0,
      },
      dateRange: [startTime, endTime],
      dataSource: [],
      modalData: {},
      query: {
        startTime: startTime,
        endTime: endTime,
        userId: userId ? userId : -1,
      },
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .startOf("d")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .endOf("d")
          .format("YYYY-MM-DD HH:mm:ss");
      }
    },

    resetPage() {
      this.query = {
        startTime: "2021-08-10 15:50:26",
        endTime: "2022-09-23 15:50:26",
        userId: -1,
      };
    },

    changeArea(e) {
      console.log(e);
    },

    doDetail(id) {
      this.$router.push("/user/detail/" + id);
    },

    getList() {
      fetchOrderStatistics({
        ...this.query,
      }).then((res) => {
        let list = res.data.data || [];
        this.dataSource = list;
      });
    },
  },
};
</script>
